import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="New Ventures"
        paragraph="Elfonze Technologies Expands Horizons Embracing New Ventures with Vision and Vigor"
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/media-coverage/media-3.png" alt="" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont">
                          <h4 className="extra-title">
                          Elfonze Technologies joins the Juggernaut in Bhubaneswar!
                          </h4>
                          <div className="spacial">
                            <p>
                              Elfonze Technologies held an electrifying brainstorming session at its
                              luxurious office in the heart of Bengaluru, sparking the launch of exciting new ventures.
                              Nestled near the picturesque Cubbon Park, our Lavelle Road office provided the perfect backdrop for 
                              this innovative gathering
                            </p>
                          </div>
                          <p>
                                Founded in February 2021, ElfOnze embraces the ethos of unity and growth, laying the foundation for a collaborative exchange of ideas. Renowned for our IT and ITES consulting services, we are now expanding our horizons to Bhubaneshwar, tapping into the city's vibrant talent pool under the leadership of industry stalwart Mr. Bichitra Nanda Palai. Mr. Palai's vision is to cultivate top-notch talent for the Oracle Enterprise Cloud and Digital space in the Eastern Region
                          </p>

                          {/* <h6>- We all intend to plan ahead.</h6> */}

                          <p>
                              In Bengaluru, Mr. Venkatakrishnan R, armed with over 18 years of industry experience, spearheads the ElfOnze Centre of Excellence. His mission is to mold aspiring professionals into industry-ready experts across various domains, from Packaged Applications to Digital innovations
                          </p>
                          <p>
                              The daylong session was a testament to our commitment to innovation and growth, with a particular emphasis on the burgeoning ESDM sector. We discussed plans to bolster the workforce for tech giants and industrial corridors, aligning with government initiatives to promote semiconductor and electronics manufacturing.
                          </p>
                          <div className="quotes text-center">
                            <p>
                              Amidst the strategic discussions, there was no shortage of camaraderie and laughter, underscoring our belief in fostering a happy working culture. As plates filled with sumptuous lunch fueled fruitful conversations about market trends and future technologies, the day culminated in a celebratory cocktail dinner at the Bowring Institute.
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/media-coverage/media-3.2.jpg" alt="" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/media-coverage/media-3.3.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                          <p>
                            At ElfOnze, we are firm believers in the power of dreaming big and turning our aspirations into reality. As we embark on this new chapter of growth and innovation, we invite you to join us on this exhilarating journey towards a brighter future.
                          </p>
                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              {/* <a href="#0">Web</a>,<a href="#0">Themeforest</a>,
                              <a href="#0">ThemesCamp</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - New Ventures</title>
      <meta key="description" name="description" 
        content="Elfonze Technologies Expands Horizons Embracing New Ventures with Vision and Vigor" />
    </>
  )
}

export default BlogDetailsDark;
